<template>
  <!-- Header -->
  <div
    class="home-page"
    @scroll="handleScroll">
    <header
      ref="home"
      role="banner"
      class="fp-active fixed-top">
      <div class="container">
        <nav
          role="navigation"
          class="navbar navbar-expand-lg px-0">
          <a
            class="navbar-brand"
            accesskey="a"
            tabindex="0"
            href="/">
            <img
              src="../../public/img/logo.svg"
              :alt="$t('app_title')">
          </a>
          <!-- Mobile menu -->
          <button
            class="btn navbar-toggler responsive-menue-close d-xl-none p-0"
            type="button"
            tabindex
            @keyup.enter="mobileMenu"
            @click="mobileMenu">
            <img
              class="menu-icon"
              :alt="$t('menu')"
              src="../../public/img/menu.svg">
          </button>
          <div
            v-if="mobileMenuCollapse"
            class="responsive-menu navbar-collapse-4 justify-content-center">
            <button
              tabindex
              @keyup.enter="mobileMenu"
              @click="mobileMenu">
              <img
                class="menu-icon"
                :alt="$t('cancel')"
                style="width: 1.3em;height: 1.3em;"
                src="../../public/img/cancel.svg">
            </button>
            <ul class="navbar-nav justify-content-center">
              <li>
                <a
                  accesskey="b"
                  tabindex
                  @keyup.enter="closeMenuTo('home')"
                  @click="closeMenuTo('home')">
                  <img
                    src="../../public/img/logo.svg"
                    height="50"
                    class="mt-2 mb-5"
                    :alt="$t('app_title')">
                </a>
              </li>
              <li class="nav-item active">
                <a
                  accesskey="c"
                  tabindex
                  class="nav-link"
                  @keyup.enter="closeMenuTo('ecosystem')"
                  @click="closeMenuTo('ecosystem')">{{ $t('header.features') }}</a>
              </li>
              <li class="nav-item">
                <a
                  accesskey="d"
                  tabindex
                  class="nav-link"
                  @keyup.enter="closeMenuTo('features')"
                  @click="closeMenuTo('features')">{{ $t('header.devices') }}</a>
              </li>
              <li class="nav-item">
                <a
                  accesskey="e"
                  tabindex
                  class="nav-link"
                  href="/blog">{{ $t('header.blog') }}</a>
              </li>
              <li class="nav-item">
                <a
                  accesskey="e"
                  tabindex
                  class="nav-link"
                  href="/docs">{{ $t('header.docs') }}</a>
              </li>
              <li class="nav-item">
                <a
                  accesskey="f"
                  tabindex
                  class="nav-link"
                  rel="noopener"
                  target="_blank"
                  :href="$t('hook')">{{ $t('header.hook') }}</a>
              </li>
            </ul>
          </div>
          <!-- Desktop menu -->
          <div class="collapse navbar-collapse">
            <ul class="navbar-nav justify-content-end ml-auto">
              <li class="nav-item ml-3">
                <a
                  class="nav-link"
                  accesskey="g"
                  tabindex
                  @keyup.enter="goto('ecosystem')"
                  @click="goto('ecosystem')">{{ $t('header.features') }}</a>
              </li>
              <li class="nav-item ml-3">
                <a
                  class="nav-link"
                  accesskey="h"
                  tabindex
                  @keyup.enter="goto('features')"
                  @click="goto('features')">{{ $t('header.devices') }}</a>
              </li>
              <li class="nav-item ml-3">
                <a
                  tabindex
                  class="nav-link"
                  href="/blog" >{{ $t('header.blog') }}</a>
              </li>
              <li class="nav-item">
                <a
                  tabindex
                  class="nav-link ml-3"
                  href="/docs" >{{ $t('header.docs') }}</a>
              </li>
              <li class="nav-item ml-3">
                <a
                  class="nav-link"
                  rel="noopener"
                  target="_blank"
                  :href="$t('hook')">{{ $t('header.hook') }}</a>
              </li>
            </ul>
            <a
              class="btn btn-primary ml-md-3 ml-lg-4"
              target="_blank"
              rel="noopener"
              accesskey="l"
              tabindex
              :href="$t('ubeac')"
            >{{ $t('signin') }}</a>
          </div>
        </nav>
      </div>
    </header>
    <!-- Section 1 -->
    <section class="fdb-block section-1 pb-4">
      <div class="container">
        <div class="row pt-2">
          <div class="col-12 col-lg-6 pr-lg-5 order-2 order-lg-1 pt-lg-4 pl-lg-5">
            <h1 class="mb-4 h2">
              {{ $t('section_1.title') }}
            </h1>
            <h2
              class="first-section-h2 mb-3 text-bold">
              {{ $t('section_1.description') }}
            </h2>
            <p class="lead">
              {{ $t('section_1.details') }}
            </p>
            <p class="lead text-special mb-3 text-bold mb-md-5">
              {{ $t('section_1.special') }}
            </p>
            <div class="col-12 col-md-6 col-lg-12 col-xl-8 p-0">
              <p class="mt-4 btn-signin mb-2 text-center text-lg-left">
                <a
                  class="btn btn-success"
                  target="_blank"
                  rel="noopener"
                  accesskey="n"
                  tabindex
                  :href="$t('ubeacRegister')">
                  {{ $t('signin_free') }}
                </a>
              </p>
              <p class="btn-description btn-signin-description">
                <small
                  class="m-0">
                  {{ $t('signin_desc') }}
                  <a
                    target="_blank"
                    class="ml-2"
                    :href="$t('ubeacSignin')">
                    {{ $t('signin_here') }}
                  </a>
                </small>
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0 order-1 order-lg-2 section-intro-b">
            <div class="section-1-feature-artwork">
              <img
                :alt="$t('app_title')"
                src="../../public/img/artwork/artwork-intro-b.png">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Section 2 -->
    <section
      id="features"
      ref="ecosystem"
      class="fdb-block section-2 section-bg">
      <!-- Part 1 -->
      <div class="text-left align-items-center py-3 px-3 px-md-0 section-part--odd">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-12 col-md-6 p-0 order-1 my-auto">
              <div class="fr-wrapper">
                <div class="fr-element fr-view">
                  <p class="text-center mb-0">
                    <img
                      alt="image"
                      class="img-fluid"
                      src="../../public/img/artwork/cloud-platform.png">
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 p-0 order-2 my-auto">
              <div class="col-12 col-lg-9 px-0 px-xl-5 ml-0 ml-lg-5 pr-md-5 pr-lg-0 pt-md-0 pt-4">
                <div class="text-center text-md-left">
                  <h2 class="mb-3">
                    {{ $t('section_2.feature_1') }}
                  </h2>
                  <p class="lead mb-0">
                    {{ $t('section_2.feature_1_desc') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Part 2 -->
      <div class="text-left align-items-center px-3 px-md-0 section-part--even">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-12 col-md-6 p-0 order-2 order-md-1 my-auto">
              <div class="col-12 col-md-10 col-lg-9 float-right px-0 px-xl-5 mr-0 mr-lg-5 pt-md-0 pt-4">
                <div class="text-center text-md-left">
                  <h2 class="mb-3">
                    {{ $t('section_2.feature_2') }}
                  </h2>
                  <p class="lead mb-0">
                    {{ $t('section_2.feature_2_desc') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 p-0 order-1 order-md-2 my-auto">
              <div class="fr-wrapper">
                <div class="fr-element fr-view">
                  <p class="text-center mb-0">
                    <img
                      alt="image"
                      class="img-fluid"
                      src="../../public/img/artwork/integration.png">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Part 3 -->
      <div class="text-left align-items-center px-3 px-md-0 order-1 section-part--odd">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-12 col-md-6 p-0 order-1 order-md-2 my-auto">
              <div class="fr-wrapper">
                <div class="fr-element fr-view">
                  <p class="text-center mb-0">
                    <img
                      alt="image"
                      class="img-fluid"
                      src="../../public/img/artwork/data-insights.png">
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 p-0 order-2 my-auto">
              <div class="col-12 col-lg-9 px-0 px-xl-5 ml-0 ml-lg-5 pr-md-5 pr-lg-0 pt-md-0 pt-4">
                <div class="text-center text-md-left">
                  <h2 class="mb-3">
                    {{ $t('section_2.feature_3') }}
                  </h2>
                  <p class="lead mb-0">
                    {{ $t('section_2.feature_3_desc') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Part 4 -->
      <div class="text-left align-items-center px-3 px-md-0 section-part--even">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-12 col-md-6 p-0 order-2 order-md-1 my-auto">
              <div class="col-12 col-md-10 col-lg-9 float-right px-0 px-xl-5 mr-0 mr-lg-5 pt-md-0 pt-4">
                <div class="text-center text-md-left">
                  <h2 class="mb-3">
                    {{ $t('section_2.feature_4') }}
                  </h2>
                  <p class="lead mb-0">
                    {{ $t('section_2.feature_4_desc') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 p-0 order-1 order-md-2 my-auto">
              <div class="fr-wrapper">
                <div class="fr-element fr-view">
                  <p class="text-center mb-0">
                    <img
                      alt="image"
                      class="img-fluid"
                      src="../../public/img/artwork/supported-devices.png">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Part 5 -->
      <div class="text-left align-items-center px-3 px-md-0 section-part--odd">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-12 col-md-6 p-0 order-1 my-auto">
              <div class="fr-wrapper">
                <div class="fr-element fr-view">
                  <p class="text-center mb-0">
                    <img
                      alt="image"
                      class="img-fluid"
                      src="../../public/img/artwork/sensor-types.png">
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 p-0 order-2 my-auto">
              <div class="col-12 col-lg-9 px-0 px-xl-5 ml-0 ml-lg-5 pr-md-5 pr-lg-0 pt-md-0 pt-4">
                <div class="text-center text-md-left">
                  <h2 class="mb-3">
                    {{ $t('section_2.feature_5') }}
                  </h2>
                  <p class="lead mb-0">
                    {{ $t('section_2.feature_5_desc') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Part 6 -->
      <div class="text-left align-items-center px-3 px-md-0 section-part--even">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-12 col-md-6 p-0 order-2 order-md-1 my-auto">
              <div class="col-12 col-md-10 col-lg-9 float-right px-0 px-xl-5 mr-0 mr-lg-5 pt-md-0 pt-4">
                <div class="text-center text-md-left">
                  <h2 class="mb-3">
                    {{ $t('section_2.feature_6') }}
                  </h2>
                  <p class="lead mb-0">
                    {{ $t('section_2.feature_6_desc') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 p-0 order-1 order-md-2 my-auto">
              <div class="fr-wrapper">
                <div class="fr-element fr-view">
                  <p class="text-center mb-0">
                    <img
                      alt="image"
                      class="img-fluid"
                      src="../../public/img/artwork/connectivity.png">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Section 3 -->
    <section
      id="devices"
      ref="features"
      class="fdb-block section-3 pt-5 pt-md-0">
      <div class="container px-3">
        <div class="row text-center mt-5 px-0 px-xl-5">
          <div class="col-12 col-sm-6 col-lg-3 text-center text-lg-left pb-xl-5">
            <!-- Responsive image -->
            <img
              :alt="$t('section_3.feature_1.title')"
              class="fdb-icon mt-3 d-lg-none fdb-icon-responsive mb-3"
              src="../../public/img/artwork/step-1.png">
            <img
              :alt="$t('section_3.feature_1.title')"
              class="fdb-icon mt-3 d-none d-lg-block mb-5 mx-auto"
              src="../../public/img/artwork/step-1.png">
            <h4 class="mb-2 mb-lg-4 pr-0 ">
              {{ $t('section_3.feature_1.title') }}
            </h4>
            <p class="mb-3 px-0 px-lg-3">
              {{ $t('section_3.feature_1.desc') }}
            </p>
          </div>
          <div class="col-12 col-sm-6 col-lg-3 pt-4 pt-sm-0 text-center text-lg-left pb-xl-5">
            <!-- Responsive image -->
            <img
              :alt="$t('section_3.feature_2.title')"
              class="fdb-icon mt-3 d-lg-none fdb-icon-responsive mb-3"
              src="../../public/img/artwork/step-2.png">
            <img
              :alt="$t('section_3.feature_2.title')"
              class="fdb-icon mt-3 d-none d-lg-block mb-5 mx-auto"
              src="../../public/img/artwork/step-2.png">
            <h4 class="mb-2 mb-lg-4 pr-0">
              {{ $t('section_3.feature_2.title') }}
            </h4>
            <p class="mb-3 px-0 px-lg-3">
              {{ $t('section_3.feature_2.desc') }}
            </p>
          </div>
          <div class="col-12 col-sm-6 col-lg-3 pt-4 pt-lg-0 text-center text-lg-left pb-xl-5">
            <!-- Responsive image -->
            <img
              :alt="$t('section_3.feature_3.title')"
              class="fdb-icon mt-3 d-lg-none fdb-icon-responsive mb-3"
              src="../../public/img/artwork/step-3.png">
            <img
              :alt="$t('section_3.feature_3.title')"
              class="fdb-icon mt-3 d-none d-lg-block mb-5 mx-auto"
              src="../../public/img/artwork/step-3.png">
            <h4 class="mb-2 mb-lg-4 pr-0">
              {{ $t('section_3.feature_3.title') }}
            </h4>
            <p class="mb-3 px-0 px-lg-3">
              {{ $t('section_3.feature_3.desc') }}
            </p>
          </div>
          <div class="col-12 col-sm-6 col-lg-3 pt-4 pt-lg-0 text-center text-lg-left pb-xl-5">
            <!-- Responsive image -->
            <img
              alt="image"
              class="fdb-icon mt-3 d-lg-none fdb-icon-responsive mb-3"
              src="../../public/img/artwork/step-4.png">
            <img
              alt="image"
              class="fdb-icon mt-3 d-none d-lg-block mb-5 mx-auto"
              src="../../public/img/artwork/step-4.png">
            <h4 class="mb-2 mb-lg-4 pr-0 ">
              {{ $t('section_3.feature_4.title') }}
            </h4>
            <p class="mb-3 px-0 px-lg-3">
              {{ $t('section_3.feature_4.desc') }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section
      ref="devices"
      class="fdb-block section-devices pt-5 pt-md-0">
      <div class="col-12 mx-auto text-center">
        <h4 class="h3 pb-5">
          {{ $t('section_device.title') }}
        </h4>
        <b-container
          class="">
          <b-row class="row-fixture">
            <b-col>
              <carousel
                :nav="true"
                :nav-text="['','']"
                :dots="false"
                :margin="10"
                :items="2"
                :responsive="{
                  0:{
                    items:1
                  },
                  400:{
                    items:2
                  },
                  600:{
                    items:3
                  },
                  1000:{
                    items:5
                  },
                  1250:{
                    items:6
                  },
                  1500:{
                    items:8
                  }
                }"
                :nav-speed="true">
                <div
                  v-for="device in devices"
                  class="mb-3 text-left section-devices-img">
                  <div class="section-devices-img--position">
                    <a
                      target="_blank"
                      rel="noopener"
                      :href="device.url">
                      <b-img
                        :src="device.imageSrc"
                        class="p-3" />
                    </a>
                  </div>
                </div>
              </carousel>
            </b-col>
          </b-row>
          <p class="pr-0 text-description mx-auto text-center mb-0 pt-4">
            {{ $t('section_device.description') }}
          </p>
          <p class="pr-0 text-description mx-auto text-center">
          {{ $t('section_device.help_desc_1') }}
          <a href="#" 
             class="text-primary-color"
             data-name="info"
             data-domain="ubeca"
             data-tld="io"
             onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;">
            {{ $t('footer.contact_us') }}
          </a>
          {{ $t('section_device.help_desc_2') }}
          </p>
        </b-container>
      </div>
    </section>
    <div class="line-break-80 d-none d-md-block" />
    <!-- Section 4 -->
    <section
      ref="guide"
      class="fdb-block section-4 section-bg pt-5 pt-md-0">
      <div class="line-break-80 d-none d-md-block" />
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h3 class="h3 mb-4">
              {{ $t('section_4.title') }}
            </h3>
            <!-- <p>{{ $t('section_4.description') }}</p> -->
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <img
              :alt="$t('section_4.description')"
              class="img-fluid"
              src="../../public/img/artwork/isometric-flow.png">
          </div>
        </div>
      </div>
    </section>
    <!-- Section 5 -->
    <section
      id="consulting"
      ref="consulting"
      class="fdb-block section-5 mb-0 pb-3 pb-md-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <h3 class="h3 mb-4">
              {{ $t('section_5.title') }}
            </h3>
            <p class="text-description mx-auto text-left">
              {{ $t('section_5.description_1') }}
            </p>
            <p class="text-description mx-auto text-left">
              {{ $t('section_5.description_2') }}
              <a href="#" 
                 data-name="info"
                 data-domain="ubeca"
                 data-tld="io"
                 onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;">
                <img src="/img/mail-color.png" />
              </a>
            </p>
            <div class="mt-4 mb-2 mt-sm-5">
              <a
                class="btn btn-success"
                target="_blank"
                rel="noopener"
                accesskey="o"
                tabindex
                :href="$t('ubeacRegister')">
                <span>{{ $t('section_5.create_dashboard') }}</span>
              </a>
            </div>
            <p class="btn-description">
              <small
                class="m-0">
                {{ $t('signin_desc') }}
                <a
                  target="_blank"
                  class="ml-2"
                  :href="$t('ubeacSignin')">
                  {{ $t('signin_now') }}
                </a>
              </small>
            </p>
          </div>
        </div>
        <div class="line-break-80 d-none d-md-block" />
      </div>
    </section>
    <!-- Footer -->
    <footer
      role="contentinfo"
      class="fdb-block footer-large">
      <div class="line-break d-none d-md-block" />
      <div class="container pt-5 pt-md-0">
        <div class="row align-items-top text-center text-lg-left">
          <div class="col-12 col-md-12 col-lg-8 order-2 order-lg-1 pt-5 pt-lg-0">
            <div class="row">
              <!-- About us -->
              <div class="col-12 col-md-6 col-lg-6">
                <h2 class="h2 text-center text-md-left mb-2">
                  {{ $t('footer.about_us') }}
                </h2>
                <p class="about-us pt-2 pr-0 pr-xl-5 mr-0 mr-xl-5 text-center text-md-left">
                  {{ $t('footer.about_us_desc') }}
                </p>
              </div>
              <!-- Contact us -->
              <div class="col-12 col-md-6 col-lg-6">
                <h2 class="h2 text-center text-md-left pt-4 pt-md-0 mb-2">
                  {{ $t('footer.contact_us') }}
                </h2>
                <p class="about-us pt-2 pr-0 pr-xl-4 mb-0 mr-0 mr-xl-5 text-center text-md-left">
                  {{ $t('footer.address') }}
                </p>
                <p class="about-us pr-0 pr-xl-4 mb-0 mr-0 mr-xl-5 text-center text-md-left">
                {{ $t('footer.email') }}
                <a href="#" 
                   data-name="info"
                   data-domain="ubeca"
                   data-tld="io"
                   onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;">
                  <img src="/img/mail-white.png" />
                </a>
                </p>
              </div>
            </div>
          </div>
          <!-- Begin Mailchimp Signup Form -->
          <div class="col-12 col-md-12 col-lg-4 order-1 order-lg-2">
            <div class="footer-logo mx-auto text-center">
              <a
                accesskey="p"
                tabindex
                class="mx-auto"
                href="/">
                <img
                  :alt="$t('app_title')"
                  src="../../public/img/logo-white.svg">
              </a>
            </div>
            <div
              id="mc_embed_signup"
              class="w-100">
              <form
                id="mc-embedded-subscribe-form"
                action="https://momentaj.us7.list-manage.com/subscribe/post?u=c8754bba497c618bd7a876992&amp;id=25c0f0f9c0"
                method="post"
                name="mc-embedded-subscribe-form"
                class="validate form-inline w-100"
                target="_blank"
                novalidate>
                <div class="row mx-auto">
                  <div class="col-12">
                    <h3 class="mb-2 text-center">
                      {{ $t('subscribe') }}
                    </h3>
                    <p class="lead mb-4">
                      {{ $t('subscribe_details') }}
                    </p>
                  </div>
                </div>
                <div
                  id="mc_embed_signup_scroll"
                  class="w-100">
                  <div class="mc-field-group input-group">
                    <div class="mt-2 w-100">
                      <div class="col-12 p-0">
                        <div class="input-group">
                          <!-- eslint-disable -->
                          <input
                            aria-label="EMAIL"
                            id="mce-EMAIL"
                            type="email"
                            name="EMAIL"
                            class="required email form-control input-subcribe mr-2"
                            :placeholder="$t('email')">
                          <!-- eslint-enable -->
                          <button
                            id="mc-embedded-subscribe"
                            class="btn btn-fab btn-subscribe form-control"
                            value="Subscribe"
                            name="subscribe"
                            type="submit">
                            <img
                              alt="$t('plus')"
                              src="../../public/img/plus.svg">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="mce-responses"
                    class="clear">
                    <div
                      id="mce-error-response"
                      class="response mt-3 subscribe-error"
                      style="display:none" />
                    <div
                      id="mce-success-response"
                      class="response mt-3"
                      style="display:none" />
                  </div>
                  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div
                    style="position: absolute; left: -5000px;"
                    aria-hidden="true">
                    <!-- eslint-disable -->
                    <input
                      id="emailmc"
                      type="text"
                      name="b_c8754bba497c618bd7a876992_25c0f0f9c0"
                      tabindex="-1"
                      value="">
                    <!-- eslint-enable -->
                  </div>
                </div>
              </form>
            </div>
            <!--End mc_embed_signup-->
          </div>
          <!-- Footer menu -->
          <div class="w-100 order-3 order-lg-3 pt-5 pt-md-0">
            <div class="col-12 col-md-6 col-lg-8 float-left">
              <p class="pr-0 pr-xl-5 mr-0 mr-xl-5 footer-link pt-3">
                <nav class="nav flex-row justify-content-around  justify-content-md-start">
                  <a
                    class="nav-link active pl-0 pr-3 pr-sm-5 pr-md-4 pr-lg-5"
                    accesskey="y"
                    @keyup.enter="goto('ecosystem')"
                    @click="goto('ecosystem')">{{ $t('header.features') }}</a>
                  <a
                    class="nav-link  pl-0 pr-3 pr-sm-5 pr-md-4 pr-lg-5"
                    accesskey="z"
                    @keyup.enter="goto('features')"
                    @click="goto('features')">{{ $t('header.devices') }}</a>
                  <a
                    class="nav-link pl-0 pr-3 pr-sm-5 pr-md-4 pr-lg-5"
                    accesskey="w"
                    @keyup.enter="goto('consulting')"
                    @click="goto('consulting')">{{ $t('header.consulting') }}</a>
                  <a
                    class="nav-link pl-0 pr-3 pr-sm-5 pr-md-4 pr-lg-5"
                    accesskey="w"
                    href="/blog">
                    Blog
                  </a>
                  <a
                    class="nav-link pl-0 pr-3 pr-sm-5 pr-md-4 pr-lg-5"
                    accesskey="w"
                    href="/docs">
                    Docs
                  </a>
                  <a
                    class="nav-link pl-0 pr-0"
                    accesskey="v"
                    target="_blank"
                    :href="$t('hook')">{{ $t('header.hook') }}</a>
                </nav>
              </p>
            </div>
            <div class="col-12 col-md-6 col-lg-4 float-right">
              <b-row class="h1 social-icon text-center justify-content-around pt-4 mb-4">
                <b-col class="p-0">
                  <button
                    class="btn btn-fab"
                    type="button">
                    <a
                      accesskey="s"
                      target="_blank"
                      rel="noopener"
                      :href="$t('twitter')">
                      <img
                        alt="$t('twitter')"
                        src="../../public/img/twitter.svg">
                    </a>
                  </button>
                </b-col>
                <b-col class="p-0">
                  <button
                    class="btn btn-fab"
                    type="button">
                    <a
                      target="_blank"
                      rel="noopener"
                      accesskey="q"
                      :href="$t('facebook')">
                      <img
                        alt="$t('facebook')"
                        src="../../public/img/facebook.svg">
                    </a>
                  </button>
                </b-col>
                <b-col class="p-0">
                  <button
                    class="btn btn-fab"
                    type="button">
                    <a
                      target="_blank"
                      rel="noopener"
                      accesskey="x"
                      :href="$t('linkedin')">
                      <img
                        alt="$t('linkedin')"
                        src="../../public/img/linkedin.svg">
                    </a>
                  </button>
                </b-col>
                <b-col class="p-0">
                  <button
                    class="btn btn-fab"
                    type="button">
                    <a
                      target="_blank"
                      rel="noopener"
                      accesskey="t"
                      :href="$t('youtube')">
                      <img
                        alt="$t('youtube')"
                        src="../../public/img/youtube.svg">
                    </a>
                  </button>
                </b-col>
                <b-col class="p-0">
                  <button
                    class="btn btn-fab"
                    type="button">
                    <a
                      target="_blank"
                      rel="noopener"
                      accesskey="oq"
                      :href="$t('github')">
                      <img
                        alt="$t('github')"
                        src="../../public/img/github-logo.svg">
                    </a>
                  </button>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div class="text-center px-3 py-4 copy-right">
      <span>
        {{ $t('copyright') }}
      </span>
    </div>
  </div>
</template>
<script>
import carousel from 'vue-owl-carousel'
export default {
  name: 'HomePage',
  components: { carousel },
  data () {
    return {
      mobileMenuCollapse: false,
      devices: [
        {
          imageSrc: '/img/logo/raspberrypi.png',
          url: 'https://www.raspberrypi.org/'
        },
        {
          imageSrc: '/img/logo/arduino.png',
          url: 'https://www.arduino.cc/'
        },
        {
          imageSrc: '/img/logo/ab.png',
          url: 'https://www.aprbrother.com/en/index.htm'
        },
        {
          imageSrc: '/img/logo/beacon.png',
          url: 'https://play.google.com/store/apps/details?id=com.bridou_n.beaconscanner'
        },
        {
          imageSrc: '/img/logo/mist.png',
          url: 'https://www.mist.com/'
        },
        {
          imageSrc: '/img/logo/data-collector.png',
          url: 'https://play.google.com/store/apps/details?id=tech.unismart.dc'
        },
        {
          imageSrc: '/img/logo/ruuvi.png',
          url: 'https://ruuvi.com'
        },
        {
          imageSrc: '/img/logo/orangepi.png',
          url: 'http://www.orangepi.org/'
        },
        {
          imageSrc: '/img/logo/bpi.png',
          url: 'http://www.banana-pi.org/'
        },
        {
          imageSrc: '/img/logo/ingics.png',
          url: 'https://www.ingics.com/'
        },
        {
          imageSrc: '/img/logo/blue-cats.png',
          url: 'https://www.bluecats.com/'
        },
        {
          imageSrc: '/img/logo/jaalee.png',
          url: 'https://www.jaalee.com/'
        }
      ]
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    if (window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.ignore) {
    } else {
      let amazonScript = document.createElement('script')
      amazonScript.setAttribute('src', 'https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js')
      document.head.appendChild(amazonScript)
    }
    // //  let customScript = document.createElement('script')
    //  customScript.setAttribute('src', '/static/lib/mailchimp.js')
    //  document.head.appendChild(customScript)
  },
  methods: {
    mobileMenu () {
      this.mobileMenuCollapse = !this.mobileMenuCollapse
    },
    goto (refName) {
      let element = this.$refs[refName]
      var top = element.offsetTop - 76
      window.scrollTo(0, top)
    },
    closeMenuTo (refName) {
      this.mobileMenuCollapse = false
      let element = this.$refs[refName]
      var top = element.offsetTop - 70
      window.scrollTo(0, top)
    },
    handleScroll (event) {
      if (window.scrollY > 0) {
        let header = document.querySelector('header')
        header.classList.add('shadow-section')
      }
      if (window.scrollY === 0) {
        let header = document.querySelector('header')
        header.classList.remove('shadow-section')
      }
    }
  }
}
</script>
