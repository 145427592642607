export default {
  language: 'en',
  app_title: 'uBeac',
  create_hook: 'Create Hook',
  /* eslint-disable */
  ubeac: 'https://app.ubeac.io',
  ubeacRegister: 'https://app.ubeac.io/register',
  ubeacSignin: 'https://app.ubeac.io/login',
  hook: 'https://hook.ubeac.io',
  linkedin: 'https://www.linkedin.com/company/ubeac/',
  twitter: 'https://twitter.com/ubeacio',
  facebook: 'https://www.facebook.com/ubeac.io',
  youtube: 'https://www.youtube.com/channel/UCxHu4X1zLUkS8amK5EwdBXg/featured',
  github: 'https://ubeac.github.io/docs/',
  contact: 'info@ubeac.io',
  /* eslint-enable */
  menu: 'Menu',
  EMAIL: 'EMAIL',
  signin: 'Sign in',
  signin_free: 'Get Started for Free',
  signin_desc: 'Already have an account?',
  signin_here: 'Sign in here',
  signin_now: 'Sign in now',
  subscribe: 'Subscribe now and get our newsletter',
  subscribe_details: 'Sign up to receive our newsletters with the latest updates from the uBeac team.',
  email: 'Email:',
  header: {
    features: 'Features',
    ecosystem: 'Ecosystem',
    guide: 'Guide',
    hook: 'Hook',
    about: 'About',
    devices: 'Devices',
    docs: 'Docs',
    blog: 'Blog',
    consulting: 'Consulting'
  },
  section_1: {
    title: 'uBeac: Adaptive IoT Platform ',
    description: 'Accelerated IoT Solutions, Insightful Real-Time Data, Cutting-Edge Digital Transformations',
    special: 'Secure IoT platform to build or retrofit a better connected IoT infrastructure',
    details: 'uBeac is a versatile IoT platform for centralized digital transformation, data integration, and visualization. uBeac’s IoT hub allows you to connect, process, and visualize real-time data in a secure way. Build an enterprise IoT solution that securely scales to millions of devices.'
  },
  section_2: {
    title: 'uBeac Ecosystem',
    feature_1: 'Cloud Platform',
    feature_1_desc: 'Want scalability, speed, and security? Use our freeware hosted cloud-based service, focus on your IoT solution, and rest easy ensured of uBeac’s high availability, reliability, scalability, and security. Connect your gateways and devices with defined URIs, IPs, certificates, custom headers, and usernames/passwords.',
    feature_2: 'Integration',
    feature_2_desc: 'Combine your data flow with external services using custom scripts and powerful API. Route device data back to existing services such as AWS, IBM, or Microsoft Power BI. Integrate with external messaging services such as IFTTT, Twilio, WhatsApp, Telegram, SMS, and Email to enable alerts and triggers.',
    feature_3: 'Data Insights',
    feature_3_desc: 'Focus on your goals knowing that nothing is slipping through the cracks. Utilize charts, indicators, gauges, maps, and graphs for seamless visualizations and create dashboards using these widgets to deliver insights. Real-time and historical time-series IoT data are available for dynamic analytics.',
    feature_4: 'Supported Devices',
    feature_4_desc: 'Connect ESP32, ESP8266, Arduino, Raspberry Pi, Onion, smart phones, and other industrial IoT gateways to our IoT hub.  We process gateway data and extract sensor data using edge computing. If you are a new manufacturer, contact us and we will implement your gateway’s proprietary processor.',
    feature_5: 'Sensor Types',
    feature_5_desc: 'There are many predefined sensor types with standard units in our platform, but you are not limited by our selection. You may define your own sensor type and unit. We support location (GPS), temperature, humidity, acceleration, rotational motion, current, voltage, magnetic field, pressure, and illuminance.',
    feature_6: 'Connectivity',
    feature_6_desc: 'Connect any device over Wi-Fi, LoRa, GPRS, LTE, NB-IoT, BLE, Zigbee, Thread, and Z-Wave. uBeac’s IoT platform supports HTTP, WebSocket, MQTT, and CoAP messaging protocols to connect devices. Our GitHub repository guides you with examples of how to connect custom devices to our IoT hub.'
  },
  section_3: {
    title: 'Ease of Use',
    description: 'Provision, monitor and control your IoT entities in secure way using rich server-side APIs.Collect and store telemetry data in scalable and fault-tolerant ways. Visualize your data with dozens of built-in or custom widgets and flexible dashboards.',
    feature_1: {
      title: 'Connect',
      desc: 'Take advantage of our predefined IoT libraries to connect your gateways and devices to our IoT hub, or benefit from uBeac’s versatility and write your own code. Register for FREE and setup your gateway.'
    },
    feature_2: {
      title: 'Collect',
      desc: 'Configure your gateways to securely send IoT data to our cloud via MQTT or HTTP. We support different payload types such as JSON, CSV, raw text, and binary which are processed and stored without latency.'
    },
    feature_3: {
      title: 'Visualize',
      desc: 'Create custom dashboards. Choose between dozens of built-in widgets. Drag and drop to create compelling visualizations of real-time data. Share dashboards with customers or colleagues.'
    },
    feature_4: {
      title: 'Analyze',
      desc: 'Access real-time and historical data in multiple formats via uBeac’s rich API. Identify trends and take actions based on real-time insights. Write your own applications and monetize your data.'
    }
  },
  section_4: {
    title: ' How uBeac works',
    description: 'Utilize dozens of widgets to display your data. Monitor your data in any format you like. Share your Dashboards and/or Reports with other teammates.',
    details: 'uBeac'
  },
  section_5: {
    title: 'Free Consultations',
    description_1: 'Have an idea? Share it with us and our experts will help you find the best solution. We are passionate about cutting-edge IoT applications and are eager to offer advice.',
    description_2: 'We offer additional services and provide solutions tailored for your business. For white-labeling, on-premise deployments, and in-depth consultations, contact us at ',
    create_dashboard: 'Create your IoT Dashboard',
    details: 'uBeac'
  },
  section_device: {
    title: 'Compatible devices',
    description: 'Don’t have a device? Don’t worry – you can experiment using your phone’s smart sensors.',
    help_desc_1: 'Have a device that’s not listed?',
    help_desc_2: '– we can implement your device for free.'
  },
  footer: {
    about_us: 'About us',
    about_us_desc: 'uBeac offers an end-to-end IoT cloud platform that transforms the way businesses create value from connected devices and user interactions.',
    contact_us: 'Contact us',
    address: '180 West Beaver Creek Road, Richmond Hill, ON, L4B1B4, CANADA',
    phone: 'Phone: (647) 696 4343',
    email: 'Email:'
  },
  copyright: 'COPYRIGHT © 2019 UBEAC, ALL RIGHTS RESERVED. PRIVACY POLICY / TERMS OF SERVICE.'
}
