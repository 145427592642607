import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueAnalytics from 'vue-analytics'
import i18n from './i18n'
import VueGtag from 'vue-gtag'

Vue.use(VueAnalytics, {
  id: 'UA-135535158-1',
  router
})
Vue.use(VueGtag, {
  config: { id: 'UA-135535158-1' }
})
Vue.config.productionTip = false
Vue.use(BootstrapVue)

new Vue({
  router,
  store,
  i18n,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
    if (window.performance) {
      var timeSincePageLoad = Math.round(performance.now())
      this.$gtag.event('timing_complete', {
        'name': 'loadTime',
        'value': timeSincePageLoad,
        'event_category': 'JS Dependencies'
      })
    }
  },
  render: h => h(App)
}).$mount('#app')
