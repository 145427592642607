// TODO: remove unwanted line before release
export default {
  apiServerUrl: 'https://api.ubeac.io/',
  socketURL: 'https://socket.ubeac.io/socket',
  identityServerURL: 'https://idsrv.ubeac.io/',
  map: {
    defaultMapCenter: {
      lng: -79.3877714524686,
      lat: 43.84633237422835
    }
  },
  colors: ['#D91A6D', '#E3CE84', '#F76F7F', '#391E47', '#009AB5', '#00659D', '#0D3600', '#6F5487', '#BA733B', '#9A024F'],
  lodash: {
    name: '_'
  },
  dateFormat: {
    global: 'YYYY/MM/DD HH:mm:ss.SSS',
    short: 'HH:mm:ss',
    medium: 'MMM DD H:m:ss'
  },
  defaultRoute: {
    afterLogin: '/Intro',
    afterLogout: '/login',
    afterForgotPassword: '/login',
    afterResetPassword: '/login',
    afterChangePassword: '/logout'
  },
  notification: {
    duration: 6000
  },
  nProgress: {
    latencyThreshold: 100, // Number of ms before progressbar starts showing, default: 100,
    router: true, // Show progressbar when navigating routes, default: true
    http: true, // Show progressbar when doing Vue.http, default: true
    easing: 'ease',
    speed: 1,
    showSpinner: false
  },
  locale: 'en',
  veeValidation: {
    errorBagName: 'errors', // change if property conflicts
    fieldsBagName: 'validationFields',
    delay: 0,
    locale: 'en',
    strict: false,
    dictionary: null,
    classes: true,
    classNames: {
      touched: 'touched', // the control has been blurred
      untouched: 'untouched', // the control hasn't been blurred
      valid: 'valid', // model is valid
      invalid: 'is-invalid', // model is invalid
      pristine: 'pristine', // control has not been interacted with
      dirty: 'dirty' // control has been interacted with
    },
    events: 'change|input|paste|blur',
    inject: true,
    validity: false,
    aria: true
  },
  docs: {
    teamsDoc: 'https://ubeac.github.io/docs/Walkthrough.html#3-creating-a-team',
    buildingsDoc: 'https://ubeac.github.io/docs/Walkthrough.html#4-creating-building-and-floors',
    floorsDoc: 'https://ubeac.github.io/docs/Walkthrough.html#4-creating-building-and-floors',
    gatewaysDoc: 'https://ubeac.github.io/docs/Walkthrough.html#5-creating-new-gateway',
    devicesDoc: 'https://ubeac.github.io/docs/Walkthrough.html#7-devices',
    sensorsDoc: 'https://ubeac.github.io/docs/Walkthrough.html#7-devices',
    dashboardsDoc: 'https://ubeac.github.io/docs/Walkthrough.html#8-create-your-dashboard',
    widgetsDoc: 'https://ubeac.github.io/docs/Walkthrough.html#8-create-your-dashboard',
    gateway: 'https://ubeac.github.io/docs/Walkthrough.html#5-creating-new-gateway',
    intro: 'https://ubeac.github.io/docs'
  }
}
