<template>
    <router-view />
</template>

<script>
export default {
  name: 'App',
  mounted () {
    if (window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.ignore) {
    } else {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments)
          }
        h._hjSettings = { hjid: 1222731, hjsv: 6 }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    }
    this.getUtmValues()
  },
  methods: {
    getQueryVariable (variable) {
      var query = window.location.search.substring(1)

      var vars = query.split('&')
      if (vars.length === 0) return null
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return null
    },

    getUtmValues () {
      var utmInfo = {
        utm_source: this.getQueryVariable('utm_source'),
        utm_medium: this.getQueryVariable('utm_medium'),
        utm_campaign: this.getQueryVariable('utm_campaign')
      }
      // window.localStorage.setItem('utmInfo', JSON.stringify(utmInfo));
      if (utmInfo.utm_source && utmInfo.utm_medium && utmInfo.utm_campaign) {
        this.editLinks(
          utmInfo.utm_source,
          utmInfo.utm_medium,
          utmInfo.utm_campaign
        )
      }
    },

    editLinks (source, medium, campaign) {
      var ubeacLinks = document.querySelectorAll(
        `a[href^='https://app.ubeac.io']`
      )
      for (var i = 0; i < ubeacLinks.length; i++) {
        var link = ubeacLinks[i]
        link.href =
          link.href +
          `?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`
      }
    }
  }
}
</script>
<style lang='scss'>
@import 'scss/main'
</style>
